import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle"
import Seo from "../components/Seo"

const GettingStartedPage = () => (
  <Layout>
    <Seo title="Getting Started" description="Upfeed getting started guide" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="getting-started col-md-8">
          <Pagetitle>Getting Started</Pagetitle>
          <p>A quick guide to help you get started with the UpFeed App:</p>
          <ol className="tldr">
            <li>
              <a href="#generate">Generate your product feed</a>
            </li>
            <li>
              <a href="#googlecats">Set Google Product Categories</a>
            </li>
            <li>
              <a href="#platforms">
                Add product feed URL to Pinterest, Facebook and Google
              </a>
            </li>
            <li>Start running your dynamic ads!</li>
          </ol>

          <h3 id="generate">
            <span class="itemno">1</span> Generating a product feed
          </h3>
          <p>
            A after installing the app in your store the feed is automatically
            generated. Simply copy the feed url from the app homepage.
          </p>

          <p>
            If you added or updated products after you installed the app, click
            on the 'Generate Feed' button on the app homepage to refresh the
            product feed. The feed will also automatically update overnight to
            make sure daily product changes (inventory, pricing, photos etc.)
            are reflected in your feed and dynamic ads.
          </p>

          <h3 id="googlecats">
            <span class="itemno">2</span> Setting Google Product Categories
          </h3>
          <p>
            For the Pinterest and google you're required to add a Google Product
            Category mapping to your feed. While this is not required for
            Facebook or Instagram, it is recommended because it allows the
            algorithms to better categorize your products.
          </p>
          <p>
            To map categories click on the 'Set/Edit Google Product Categories'
            link on the app homepage and then click 'Map Categories'. Map your
            current product categories, with te most relevant Google Product
            Category. You find more information about Google Product Categories
            here.
          </p>

          <h3 id="platforms">
            <span class="itemno">3</span> Add the feed to platforms:
          </h3>
          <p>
            See below for instructions on adding the feed to Pinterest,
            Facebook, Instagram and Google.
          </p>

          <h3>Adding feed to Pinterest</h3>
          <ul>
            <li>Copy the product feed url from the app homepage.</li>
            <li>In Pinterest go to Ads > Catalogs</li>
            <li>
              Click 'Get Started' then 'Add Datasource' and paste the product
              feed url.
            </li>
            <li>
              Select as file format 'CSV' and click the 'Create Pins' button to
              upload your feed to Pinterest.
            </li>
          </ul>

          <h3>Adding feed to Facebook and/or Instagram</h3>
          <ul>
            <li>Copy the product feed url from the app homepage.</li>
            <li>
              Go the{" "}
              <a href="https://business.facebook.com" target="_blank">
                Facebook's Business Manager
              </a>{" "}
              > Commerce Manager and create a new catalog (or click on your
              existing catalog)
            </li>
            <li>Then in the Catalog click on 'Product Data Sources'</li>
            <li>Click 'Add Item' and then select 'Use Bulk Upload'</li>
            <li>
              Select 'Scheduled Feed' and on the next screen enter the feed url
              from step 1
            </li>
            <li>Set the scheduled automated upload at a time past 5am EST.</li>
            <li>
              Enter a feed Name and your store's currency, then click the
              'Upload' button
            </li>
          </ul>

          <h3>Adding feed to Google Merchant Center</h3>
          <ul>
            <li>
              In the left menu under 'Products' go to 'Feeds'Click the blue
              plus-button "add a primary feed"
            </li>
            <li>
              Select your country, language and feed destinations, click
              continue
            </li>
            <li>
              On the next page, set a feed name and select 'scheduled fetch'
            </li>
            <li>
              Enter a file name 'Upfeed Product Feed', set the schedule to
              'Daily', 5am New York Time and enter the file
              url: https://zengapps.com/feeds/yourstore-feed.csv
              <br />
              Note: you don't have to set a username or password
            </li>
            <li>Click the 'Create feed' button</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default GettingStartedPage
